import React from 'react';
import ReactDOM from 'react-dom';
import '@iconify/iconify';
import './style.css';
import { App, IFooter, IHeader } from './components/layout';
import Router from './router';
import './configuration';
import { configuration, menuPages } from './configuration';

const root: HTMLElement = document.querySelector('#root');

const header: IHeader = {
    banner: configuration.banner,
    logo: {
        url: configuration.pages.home.url,
        title: configuration.title,
    },
    menu: [
        configuration.pages.home,
        ...menuPages,
        configuration.pages.eventi,
        configuration.pages.contatti,
    ],
    shopAction: configuration.actions.shop,
};

const footer: IFooter = {
    shortcuts: configuration.actions.order.map((icon) => configuration.actions[icon]),
    address: configuration.address,
    copyright: configuration.copyright,
};

ReactDOM.render(
    <App header={header} footer={footer}>
        <Router />
    </App>,
    root
);
