import React, { ChangeEventHandler } from 'react';
import styles from './styles.css';

interface IInput {
    type?: string;
    name: string;
    placeholder: string;
    required?: boolean;
}

interface ITextArea extends IInput {
    cols: number;
    rows: number;
}

export const Input = (props: IInput) => (
    <input
        className={styles.input}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
    />
);

export const TextArea = (props: ITextArea) => (
    <textarea
        className={styles.textarea}
        name={props.name}
        cols={props.cols}
        rows={props.rows}
        placeholder={props.placeholder}
        required={props.required}
    ></textarea>
);
