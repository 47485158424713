import React from 'react';
import Map from '../../components/map';
import ContactForm from './contactform';
import contactsJson from '../../../data/contacts.json';
import timetableJson from '../../../data/timetable.json';
import pageStyles from '../styles.css';
import styles from './styles.css';
import TimeTable, { ITimeTable } from '../../components/timetable';

interface IContactsConfiguration {
    description?: string;
    mapTitle?: string;
    formTitle: string;
    openingTitle: string;
}

const contactsData: IContactsConfiguration = contactsJson;
const timeTable: ITimeTable = timetableJson;

const ContactsInfo = () => (
    <div className={styles.contactInfo}>
        <div className={styles.timeTable}>
            <h2>{contactsData.openingTitle}</h2>

            <TimeTable {...timeTable} />
        </div>

        <div className={styles.form}>
            <h2>{contactsData.formTitle}</h2>

            <ContactForm />
        </div>
    </div>
);

interface IContactsView {
    title: string;
}

const ContactsView = (props: IContactsView) => (
    <div className={pageStyles.page}>
        <h1>{props.title}</h1>

        {contactsData.description && <p>{contactsData.description}</p>}

        {contactsData.mapTitle && <h2>{contactsData.mapTitle}</h2>}

        <Map />

        <ContactsInfo />
    </div>
);

export default ContactsView;
