import React from 'react';
import Markdown from 'react-markdown';
import pageStyles from '../styles.css';
import ProductItem, { IProductItem } from './product-item';
import styles from './styles.css';
import { getBrandImage } from './utils';

interface IProductCategory {
    category: string;
    description?: string;
    categoryPrice?: string;
    brand?: string;
    vertical: boolean;
    items: IProductItem[];
}

export interface IProducts {
    title?: string;
    slug?: string;
    image?: string;
    description?: string;
    products: IProductCategory[];
    notices?: string;
    warnings?: string;
}

interface IProductView extends IProducts {
    title: string;
    image?: string;
}

const ProductCategory = (props: IProductCategory) => {
    const refBrand = getBrandImage(props.brand);

    if (!props.items?.length) return (
        <div></div>
    );

    return (
        <div className={styles.category}>
            <h2 className={styles.categoryHeading}>
                <span className={styles.categoryName}>{props.category}</span>

                {props.description && (
                    <span className={styles.categoryDescription}>{props.description}</span>
                )}

                {props.categoryPrice && (
                    <span className={styles.categoryPrice}>{props.categoryPrice}</span>
                )}

                {refBrand && (
                    <div
                        className={styles.categoryBrand}
                        style={{
                            ['--brand-image' as any]: `url("${refBrand}")`,
                        }}
                    ></div>
                )}
            </h2>
            <div className={props.vertical ? styles.categoryItemsVertical : styles.categoryItems}>
                {props.items?.map((item, index) => (
                    <ProductItem key={`${index}-${item.name}`} {...item} />
                ))}
            </div>
        </div>
    );
};

const ProductsView = (props: IProductView) => (
    <div className={pageStyles.page}>
        <h1>{props.title}</h1>

        {props.description && <h2 className={styles.description}>{props.description}</h2>}

        {props.image && (
            <img className={styles.imageBanner} src={props.image} alt={props.title} />
        )}

        {(props.products || []).map((category, index) => (
            <ProductCategory key={`${index}-${category.category}`} {...category} />
        ))}

        <div className={styles.footer}>
            {props.notices && (
                <div className={styles.notices}>
                    <Markdown>{props.notices}</Markdown>
                </div>
            )}
            {props.warnings && (
                <div className={styles.warnings}>
                    <Markdown>{props.warnings}</Markdown>
                </div>
            )}
        </div>
    </div>
);

export default ProductsView;
