import React from 'react';
import styles from './styles.css';
import { getBrandImage } from '../utils';

export interface IProductItem {
    image?: string;
    prefix?: string;
    name: string;
    price?: string;
    priceNotes?: string;
    description?: string;
    ingredients?: string;
    brand?: string;
}

const ProductImage = (props: { image?: IProductItem['image'] }) => (
    <>
        {props.image && (
            <div
                className={styles.image}
                style={{ ['--product-image' as any]: `url("${props.image}")` }}
            ></div>
        )}
    </>
);

const ProductBrand = (props: { brand?: IProductItem['brand'] }) => {
    const refImage = getBrandImage(props.brand);
    return (
        <>
            {refImage && (
                <div
                    className={styles.brand}
                    style={{ ['--product-brand' as any]: `url("${refImage}")` }}
                ></div>
            )}
        </>
    );
};

const ProductItem = (props: IProductItem) => (
    <div className={styles.productItem}>
        <ProductImage image={props.image} />
        <div className={props.priceNotes ? styles.headingWithNotes : styles.heading}>
            <div className={styles.name}>
                {props.prefix && <span className={styles.previx}>{props.prefix}: </span>}
                {props.name}
            </div>
            <div className={styles.price}>
                {props.price && (
                    <>
                        <span className={styles.priceValue}>{props.price}</span>{' '}
                        <span className={styles.priceUnit}>€</span>
                    </>
                )}
                {props.priceNotes && (
                    <span className={styles.priceValue}> {props.priceNotes}</span>
                )}
            </div>
        </div>
        {props.ingredients && (
            <div className={styles.ingredients}>{props.ingredients}</div>
        )}
        {props.description && (
            <div className={styles.description}>
                {props.description.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        )}
        <ProductBrand brand={props.brand} />
    </div>
);

export default ProductItem;
