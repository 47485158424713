import React from 'react';
import styles from './styles.css';

const Map = () => (
    <div className={styles.map}>
        <iframe
            src="https://www.google.it/maps/embed?pb=!1m18!1m12!1m3!1d2480.0624586765043!2d9.294077!3d45.491986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c87c79faafed%3A0xd7a0db7c74edbf2!2sVia+Roma%2C+42%2C+20090+Segrate+MI!5e1!3m2!1sit!2sit!4v1433078567205"
            width="100%"
            height="300"
            style={{ border: 0 }}
        ></iframe>
    </div>
);

export default Map;
