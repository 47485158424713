import React from 'react';
import Icon from '../icon';
import styles from './styles.css';

interface IButton {
    children?: (string | JSX.Element) | (string | JSX.Element)[];
}

interface ILinkButton extends IButton {
    href?: string;
    title?: string;
    target?: string;
}

interface ISubmitButton extends IButton {
    name?: string;
    value: string;
}

interface IIconbutton extends ILinkButton {
    icon: string;
}

interface IActionIconbutton extends IIconbutton {
    action: Function;
}

export const LinkButton = (props: ILinkButton): JSX.Element => (
    <a
        className={styles.link}
        href={props.href}
        title={props.title}
        target={props.target}
    >
        {props.children}
    </a>
);

export const SubmitButton = (props: ISubmitButton): JSX.Element => (
    <input
        className={styles.submit}
        name={props.name}
        type="submit"
        value={props.value}
    />
);

export const IconButton = (props: IIconbutton) => (
    <a
        className={styles.iconButton}
        href={props.href}
        title={props.title}
        target={props.target}
    >
        <Icon icon={props.icon}></Icon>
    </a>
);

export const ActionIconButton = (props: IActionIconbutton) => (
    <a
        className={styles.iconButton}
        title={props.title}
        onClick={() => props.action()}
    >
        <Icon icon={props.icon}></Icon>
    </a>
);
