import React from 'react';
import styles from './styles.css';

interface ITimeTableRow {
    title: string;
    timeRows: string[];
}

export interface ITimeTable {
    timeTable: ITimeTableRow[];
    footer?: string;
}

const TimeTableRow = (props: ITimeTableRow) => (
    <div>
        <b>{props.title}</b>
        <ul className={styles.rows}>
            {props.timeRows.map((row) => (
                <li key={row}>{row}</li>
            ))}
        </ul>
    </div>
);

const TimeTable = (props: ITimeTable) => (
    <div>
        {props.timeTable.map(({ title, timeRows }, index) => (
            <TimeTableRow key={index} title={title} timeRows={timeRows}></TimeTableRow>
        ))}
        {props.footer && <div className={styles.footer}>{props.footer}</div>}
    </div>
);

export default TimeTable;
