import React from 'react';
import { useRoutes } from 'hookrouter';
import HomeView from './views/home';
import ProductsView, { IProducts } from './views/products';
import EventsView from './views/events';
import ContactsView from './views/contacts';
import NotFoundView from './views/not-found';
import { configuration } from './configuration';
import pagesJSON from '../data/menu/*.json';

const { home, eventi, contatti } = configuration.pages;

const GenericProductsViewComponent = (data: IProducts) => (
    <ProductsView
        title={data.title}
        image={data.image}
        {...data}
    />
);

const routes = {
    [home.url]: () => <HomeView />,
    [eventi.url]: () => <EventsView title={eventi.title} />,
    [contatti.url]: () => <ContactsView title={contatti.title} />,
};

for (const [ slug, data ] of Object.entries(pagesJSON)) {
    routes[`/${slug}`] = () => GenericProductsViewComponent(data as IProducts);
}

const Router = () => {
    const routeResult = useRoutes(routes);
    return routeResult || <NotFoundView />;
};

export default Router;
