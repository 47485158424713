import React from 'react';
import pageStyles from '../styles.css';
import styles from './styles.css';
import Slideshow from './slideshow';
import Markdown from 'react-markdown';
import homeJson from '../../../data/home.json';

interface IHomeSection {
    reversed?: boolean;
    images?: string[];
    title: string;
    content: string;
}

interface IHomeData {
    banner?: string;
    sections?: IHomeSection[];
    presentation?: string;
}

const homeData: IHomeData = homeJson;

interface ISectionImage {
    images: string[];
}

const SectionImage = (props: ISectionImage) => {
    const images = props.images || [];
    return (
        <div className={styles.sectionImage}>
            {images.length > 1
                ? <Slideshow images={props.images} />
                : images.map(img => (
                    <img key={img} className={styles.image} src={img} />
                ))}
        </div>
    );
};

const Section = (props: IHomeSection) => (
    <div className={props.reversed ? styles.sectionReversed : styles.section}>
        <SectionImage images={props.images} />

        <div className={styles.sectionContent}>
            <h2 className={styles.sectionTitle}>
                {props.title}
            </h2>

            <p className={styles.sectionDescription}>
                {props.content}
            </p>
        </div>
    </div>
);

const HomeView = () => (
    <div className={pageStyles.page}>
        {homeData.banner && (
            <img className={styles.imageBanner} src={homeData.banner} />
        )}
        {(homeData.sections || []).map((section, index) => (
            <Section key={index} {...section} />
        ))}

        <Markdown>{homeData.presentation}</Markdown>
    </div>
);

export default HomeView;
