import React, { Component, FormEvent, useState } from 'react';
import { SubmitButton } from '../../../components/buttons';
import { Input, TextArea } from '../../../components/input';
import styles from './styles.css';

const waitFor = (ms: number) => new Promise(resolve => {
    setTimeout(resolve, ms);
});

interface IFormHandler {
    className: string;
    children?: (string | JSX.Element) | (string | JSX.Element)[];
}

const FormHandler = (props: IFormHandler) => {
    const [sendStatus, setSendStatus] = useState('Invia');

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formEl = e.target as HTMLFormElement

        const formData = new FormData(formEl);

        setSendStatus('Invio in corso');

        fetch('/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData as any).toString(),
        })
        .then(() => {
            setSendStatus('Inviato');
            waitFor(1500).then(() => {
                setSendStatus('Invia');
                formEl.reset();
            });
        })
        .catch(() => {
            setSendStatus('Errore di invio. Riprova');
            waitFor(1500).then(() => {
                setSendStatus('Invia');
            });
        });
    };

    return (
        <form className={props.className} onSubmit={handleSubmit}>
            {props.children}

            <div className={styles.submit}>
                <SubmitButton name="send" value={sendStatus}></SubmitButton>
            </div>
        </form>
    );
};

const ContactForm = () => (
    <FormHandler className={styles.contactForm}>
        <input type="hidden" name="form-name" value="contact" />

        <div className={styles.name}>
            <Input
                type="text"
                name="name"
                placeholder="Nome"
                required
            ></Input>
        </div>

        <div className={styles.email}>
            <Input
                type="email"
                name="email"
                placeholder="Email"
                required
            ></Input>
        </div>

        <div className={styles.message}>
            <TextArea
                name="message"
                cols={30}
                rows={4}
                placeholder="Messaggio"
                required
            ></TextArea>
        </div>
    </FormHandler>
);

export default ContactForm;
