import React from 'react';
import Markdown from 'react-markdown';
import pageStyles from '../styles.css';
import eventsJson from '../../../data/events.json';
import styles from './styles.css';

interface IEvent {
    date: string;
    content?: string;
    image?: string;
}

interface IEventGroup {
    title?: string;
    events: IEvent[];
}

interface IEventGroupSection extends IEventGroup {
    groupIndex: number;
}

interface IEventsData {
    title: string;
    description?: string;
    groups: IEventGroup[];
    emptyEventsContent: string;
}

const eventsData: IEventsData = eventsJson;

const EventCard = (props: IEvent) => {
    return (
        <div className={styles.eventCard}>
            <h2 className={styles.eventCardHeading}>
                {props.date}
            </h2>

            {props.content && (
                <div className={styles.eventCardContent}>
                    <Markdown>{props.content}</Markdown>
                </div>
            )}

            {props.image && (
                <img className={styles.eventCardImage} src={props.image} />
            )}
        </div>
    );
};

const EventGroupSection = (props: IEventGroupSection) => {
    if (!props.events.length) return (
        <div></div>
    );

    return (
        <div className={styles.eventGroup}>
            <h2 className={styles.eventGroupTitle}>{props.title}</h2>

            <div className={styles.eventGroupList}>
                {(props.events || []).map((event, index) => (
                    <EventCard key={`${props.groupIndex}-${index}-event`} {...event} />
                ))}
            </div>
        </div>
    );
};

const EventsView = () => (
    <div className={pageStyles.page}>
        {eventsData.title && (
            <h1>{eventsData.title}</h1>
        )}

        {eventsData.description && (
            <Markdown>{eventsData.description}</Markdown>
        )}

        {(eventsData.groups || []).map((group, index) => (
            <EventGroupSection key={`${index}-group`} groupIndex={index} {...group} />
        ))}

        {!eventsData.groups.length && (
            <Markdown>{eventsData.emptyEventsContent}</Markdown>
        )}
    </div>
);

export default EventsView;
