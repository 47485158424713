import React, { useState } from 'react';
import { A, usePath } from 'hookrouter';
import classnames from 'classnames';
import { ActionIconButton, IconButton, LinkButton } from '../buttons';
import styles from './styles.css';
import logo from '../../img/logo.svg';
import Icon from '../icon';

interface ILogo {
    url: string;
    title: string;
}

const Logo = (props: ILogo) => (
    <A className={styles.logoLink} href={props.url} title={props.title}>
        <img className={styles.logoImage} src={logo} alt="Logo" />
    </A>
);

export interface IMenuItem {
    url: string;
    title: string;
}

const MenuItem = (props: IMenuItem) => {
    const path = usePath();
    const classes: string = classnames([
        styles.menuItem,
        {
            [styles.menuItemActive]: path === props.url,
        },
    ]);
    return (
        <A className={classes} href={props.url}>
            {props.title}
        </A>
    );
};

export interface IMenu {
    menu: IMenuItem[];
    open?: boolean;
}

const Menu = (props: IMenu) => (
    <div className={styles.menu} data-open={props.open}>
        {props.menu.map((item: IMenuItem, index: number) => (
            <MenuItem key={index} url={item.url} title={item.title} />
        ))}
    </div>
);

export interface IActionItem {
    url: string;
    text: string;
    icon: string;
}

const CallToAction = (props: IActionItem) => (
    <LinkButton href={props.url} target="_blank">
        <Icon icon={props.icon} />
        &nbsp;
        <span>{props.text}</span>
    </LinkButton>
);

export interface IHeader extends IMenu {
    banner?: string;
    logo: ILogo;
    shopAction: IActionItem;
}

export const Header = (props: IHeader) => {
    const [ menuOpen, setMenuOpen ] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const hideMenu = () => setMenuOpen(false);

    return <>
            {props.banner && (
                <div className={styles.banner}>{props.banner}</div>
            )}

            <header className={styles.header}>
                <div className={styles.headerContainer}>
                    <div className={styles.logo}>
                        <Logo {...props.logo} />
                    </div>
                    <div className={styles.menuWrapper} onClick={hideMenu}>
                        <Menu menu={props.menu} open={menuOpen}></Menu>
                    </div>
                    <div className={styles.cta}>
                        <CallToAction {...props.shopAction}></CallToAction>
                        <div className={styles.menuTrigger}>
                            <ActionIconButton title="Menu" icon="ic-menu" action={toggleMenu} />
                        </div>
                    </div>
                </div>
            </header>
        </>;
    );
};

export interface IFooter {
    shortcuts: IActionItem[];
    address: string;
    copyright: string;
}

export const Footer = (props: IFooter) => (
    <footer className={styles.footer}>
        <div className={styles.footerContainer}>
            <address className={styles.shortcuts}>
                {props.shortcuts.map((action: IActionItem, index: number) => (
                    <IconButton
                        key={index}
                        href={action.url}
                        icon={action.icon}
                        title={action.text}
                        target="_blank"
                    />
                ))}
            </address>
            <span className={styles.address}>{props.address}</span>
            <span className={styles.copyright}>&copy; {props.copyright}</span>
        </div>
    </footer>
);

export interface IApp {
    header: IHeader;
    footer: IFooter;
    children?: JSX.Element;
}

export const App = (props: IApp) => (
    <div className={styles.app}>
        <Header {...props.header}></Header>
        <div className={styles.appContent}>
            <div className={styles.container}>{props.children}</div>
        </div>
        <Footer {...props.footer}></Footer>
    </div>
);
