import React from 'react';
import styles from '../styles.css';

const NotFoundView = () => (
    <div className={styles.page}>
        <h1>Pagina non trovata</h1>
    </div>
);

export default NotFoundView;
